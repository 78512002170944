<template>
  <section class="carousel">
    <div
      class="carousel-images"
      v-for="(image, index) in imagesToShow"
      :key="image"
    >
      <transition :name="direction">
        <img
          v-show="index === visible"
          :src="image"
          :alt="index + ' Imatge portada'"
        />
      </transition>
    </div>
    <button class="carousel-left-button carousel-button" @click="previousImage">
      <i class="fas fa-chevron-left"></i>
    </button>
    <button class="carousel-right-button carousel-button" @click="nextImage">
      <i class="fas fa-chevron-right"></i>
    </button>
  </section>
</template>

<script>
export default {
  // imagesToShow son las imágenes que querémos mostrar
  name: "Carousel",
  props: ["imagesToShow"],
  data() {
    return {
      visible: 0,
      direction: "",
      carousel: null,
    };
  },

  computed: {
    imagesLenght() {
      return this.imagesToShow.length;
    },
  },

  mounted() {
    this.carouselInterval();
  },

  methods: {
    previousImage() {
      if (this.visible !== 0) {
        this.visible--;
      } else {
        this.visible = this.imagesLenght - 1;
      }
      this.direction = "slide-left";

      // Reset carousel timer
      clearInterval(this.carousel);
      this.carouselInterval();
    },

    nextImage() {
      if (this.visible === this.imagesLenght - 1) {
        this.visible = 0;
      } else {
        this.visible++;
      }
      this.direction = "slide-right";

      // Reset carousel timer
      clearInterval(this.carousel);
      this.carouselInterval();
    },

    //
    carouselInterval() {
      this.carousel = setInterval(() => {
        this.nextImage();
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  position: relative;
  width: 100%;
  min-height: 80vh;
  overflow: hidden;

  .carousel-images {
    width: 100%;
    overflow: hidden;

    img {
      position: absolute;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-75%);
    width: 50px;
    height: 150px;
    border: none;
    background-color: rgba(0, 0, 0, 0.02);
    transition: all 300ms;
    z-index: 2;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.4);
    }

    &:hover i {
      color: white;
    }

    i {
      font-size: 50px;
      color: $main-color;
    }
  }

  .carousel-left-button {
    left: 0;
  }

  .carousel-right-button {
    right: 0;
  }
}

// CAROUSEL ANIMATION - SLIDE RIGHT
.slide-right-enter-active {
  animation: slide-right-in 1s ease-in-out;
}

.slide-right-leave-active {
  animation: slide-right-out 1s ease-in-out;
}

@keyframes slide-right-in {
  from {
    opacity: 0.8;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right-out {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0.8;
    transform: translateX(-100%);
  }
}

// CAROUSEL ANIMATION  - SLIDE LEFT
.slide-left-enter-active {
  animation: slide-left-in 1s ease-in-out;
}
.slide-left-leave-active {
  animation: slide-left-out 1s ease-in-out;
}

@keyframes slide-left-in {
  from {
    opacity: 0.8;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-left-out {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0.8;
  }
}
// END ANIMATION OF CAROUSEL

@media all and (min-width: 2200px) {
  .carousel {
    min-height: 83vh;
    max-height: 83vh;
  }
}

@media all and (min-width: 980px) and (max-width: 1660px) {
  .carousel {
    min-height: 55vh;
    max-height: 55vh;
  }
}

@media all and (min-width: 300px) and (max-width: 979px) {
  .carousel {
    min-height: 35vh;
    max-height: 35vh;

    .carousel-images {
      img {
        min-height: 100%;
        max-height: 100%;
      }
    }

    .carousel-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 70px;
      border: none;
      background-color: rgba(0, 0, 0, 0.02);
      transition: all 300ms;
      z-index: 2;

      i {
        font-size: 30px;
      }
    }
  }
}
</style>