<template>
  <label :for="uuid">{{ label }}</label>
  <input
    :id="uuid"
    v-bind="$attrs"
    class="form-field"
    :placeholder="placeholder"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :aria-describedby="error ? `${uuid}-error` : null"
    :aria-invalid="error ? true : null"
  />

  <p
    :id="`${uuid}-error`"
    v-if="error"
    class="errorMessage"
    aria-live="assertive"
  >
    {{ error }}
  </p>
</template>

<script>
import UniqueID from "@/features/UniqueID";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  setup() {
    const uuid = UniqueID().getID();
    return {
      uuid,
    };
  },
};
</script>

<style lang="scss" scoped>
label {
  color: #333;
  display: block;
  font-size: 18px;
  margin-bottom: 4px;
}

.form-field {
  padding: 5px;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 30px;
  outline: none;
  margin-bottom: 25px;

  &:focus, 
  &:active {
    outline: none;
    padding: 4px;
    border: 2px solid #ccc;
  }
}

.errorMessage {
  color: red;
}
</style>