<template>
  <label :for="uuid" v-if="label">
    {{ label }}
  </label>
  <select
    :id="uuid"
    class="field"
    :value="modelValue"
    v-bind="{
      ...$attrs,
      onChange: ($event) => {
        $emit('update:modelValue', $event.target.value);
      },
    }"
  >
    <option v-if="defaultValue" value="">{{ defaultValue }}</option>
    <option
      v-for="option in options"
      :key="option"
      :value="option"
      :selected="option === modelValue"
    >
      {{ option }}
    </option>
  </select>
</template>

<script>
import UniqueID from "@/features/UniqueID";

export default {
  props: {
    label: {
      type: [String, Object],
      default: "",
    },
    defaultValue: {
      type: [String],
      default: "",
      required: false,
    },
    modelValue: {
      type: [String, Number],
      default: "Otros",
    },
    options: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const uuid = UniqueID().getID();
    return {
      uuid,
    };
  },
};
</script>

<style lang="scss" scoped>
label {
  display: block;
  margin-bottom: 4px;
}

select {
  padding: 9px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;

  &:focus {
    outline: none;
    border: 2px solid #ccc;
    padding: 8px;
  }
}
</style>