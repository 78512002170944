<template>
  <CollageMultipleInstagram
    :images="instagramContent"
    title="Últimas publicaciones de mi Instagram"
  />
</template>

<script>
import InstagramApiService from "@/services/InstagramApiService";
import CollageMultipleInstagram from "@/components/CollageMultipleInstagram.vue";

export default {
  name: "Instagram",
  components: { CollageMultipleInstagram },
  props: {
    fields: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      instagramContent: {},
      imagesLoaded: false,
    };
  },

  created() {
    InstagramApiService.getLatest(this.fields, this.token)
      .then((result) => {
        let feed = result.data.data;
        this.instagramContent = feed;
        this.imagesLoaded = true;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style lang="scss" scoped>
.instagram-images-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;

  .instagram-image-card {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-basis: 16%;
    margin: 0px 10px 40px 10px;

    .instagram-link-image {
      margin: auto;
      img {
        width: 100%;
        border-radius: $image-border-radius;
      }
    }
  }
}

@media (min-width: $tablet-query) and (max-width: $laptop-query) {
  .instagram-images-container {
    .instagram-image-card {
      flex-basis: 25%;
      width: 250px;
      margin: 10px;
    }
  }
}

@media (min-width: 300px) and (max-width: $tablet-query) {
  .instagram-images-container {
    .instagram-image-card {
      flex-basis: 40%;
      widows: 250px;
      margin: 15px;
    }
  }
}
</style>