<template>
  <section
    class="showcase"
    :style="{ backgroundImage: `url(${service.backgroundImage})` }"
  >
    <div class="showcase-wrapper">
      <!-- MAIN IMAGE -->
      <div class="main-image-container">
        <img
          class="main-image"
          :src="service.image1"
          alt="Imagen presentación primária"
        />
        <!-- ABSOLUTE IMAGE -->
        <div v-if="service.image2" class="image-centered">
          <img :src="service.image2" alt="Imagen presentación secundária" />
        </div>
      </div>

      <!-- TEXT -->
      <div class="text-image-container">
        <div class="text-title-container">
          <h2
            class="text-title"
            v-for="title in service.title"
            :key="title"
            v-html="title"
          ></h2>
        </div>
        <div class="text-description">
          <p
            v-for="description in service.description"
            :key="description"
            v-html="description"
          ></p>
          <div class="text-button-container">
            <button @click="redirectTo(service.link)" class="main-button">
              {{ service.button }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Service-Model-2",

  data() {
    return {
      service: {
        id: 1,
        title: ["Aprende con <br>", "nosotros"],
        description: [
          "Si quieres <b>iniciarte</b> en el mundo de la fotografía o perfeccionar tu técnica, te ofrecemos diversos <b>cursos</b> y <b>talleres</b> personalizados",
          "Desde el funcionamiento de tu <b>cámara</b> hasta la edición final",
        ],
        link: "/cursos",
        image1: require("@/assets/images/Showcase/rectangular.jpg"),
        image2: require("@/assets/images/Showcase/showcase2_2.jpg"),
        button: "Ver más",
        backgroundImage: require("@/assets/images/Showcase/service2.png"),
      },
    };
  },

  methods: {
    redirectTo(link) {
      this.$router.push(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.showcase {
  width: 100%;
  position: relative;
  margin-top: 200px;
  margin-bottom: 200px;
  background-size: cover;
  background-repeat: no-repeat;

  .showcase-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-content: center;
    position: relative;
    width: 100%;

    .main-image-container {
      flex-basis: 45%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      order: 2;

      .main-image {
        width: 90%;
        position: absolute;
        top: -5%;
      }

      .image-centered {
        position: relative;
        left: -20%;
        bottom: -10%;
        width: 100%;
        height: 90%;
        img {
          height: 90%;
        }
      }
    }

    .text-image-container {
      flex-basis: 45%;
      order: 1;

      .text-description {
        text-align: right;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 3rem;
        width: 83%;

        :first-child {
          margin: 0px;
        }

        p {
          font-size: 2.5rem;
        }

        .text-button-container {
          flex-basis: 100%;
          .main-button {
            width: 400px;
            height: 85px;
            text-transform: uppercase;
            font-size: 2.5rem;
          }
        }
      }

      .text-title-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        line-height: 90px;
        h2 {
          font-size: 4.5rem;
          font-family: $font-title;
          color: $main-color;
          text-transform: uppercase;
          position: relative;
          margin: 0px;
          margin-left: 0%;
        }

        :nth-child(2) {
          margin-left: 13%;
        }
      }
    }
  }
}

@media all and (max-width: 1900px) {
  .showcase {
    .showcase-wrapper {
      .text-image-container {
        .text-description {
          p {
            font-size: 2.5rem;
          }

          .text-button-container {
            .main-button {
              width: 400px;
              height: 65px;
              font-size: 2.5rem;
            }
          }
        }

        .text-title-container {
          line-height: 80px;
          bottom: -14%;
          right: 8%;
          h2 {
            font-size: 4rem;
          }
        }
      }
    }
  }
}

@media all and (min-width: 2000px) {
  .showcase {
    .showcase-wrapper {
      .text-image-container {
        .text-description {
          p {
            font-size: 3rem;
          }

          .text-button-container {
            .main-button {
              width: 500px;
              height: 80px;
              font-size: 3rem;
            }
          }
        }

        .text-title-container {
          line-height: 100px;
          bottom: -10%;
          right: 8%;
          h2 {
            font-size: 6rem;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1640px) {
  .showcase {
    .showcase-wrapper {
      .text-image-container {
        .text-description {
          p {
            font-size: 1.5rem;
          }

          .text-button-container {
            .main-button {
              width: 350px;
              height: 60px;
              font-size: 1.5rem;
            }
          }
        }

        .text-title-container {
          line-height: 50px;
          bottom: -14%;
          right: 8%;
          h2 {
            font-size: 3rem;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .showcase {
    .showcase-wrapper {
      .text-image-container {
        .text-description {
          :first-child {
            margin: 0px;
          }

          p {
            font-size: 1.5rem;
          }

          .text-button-container {
            flex-basis: 100%;
            .main-button {
              width: 200px;
              height: 55px;
              font-size: 1.2rem;
            }
          }
        }

        .text-title-container {
          line-height: 34px;
          h2 {
            font-size: 2rem;
          }
          :nth-child(2) {
            margin-left: 25%;
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .showcase {
    background-image: none !important;
    margin: 40px 0px;
    padding: 0px;
    box-sizing: border-box;
    .showcase-wrapper {
      box-shadow: 0px 0px 5px #ccc;
      width: 90%;
      margin: auto;
      border-radius: 5px;
      .main-image-container {
        flex-basis: 50%;
        margin-right: 0%;
        .main-image {
          position: relative;
          width: 100%;
          margin: 0px;
          top: 0%;
        }
      }

      .text-image-container {
        flex-basis: 50%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        background-color: #f2edf0;
        box-sizing: border-box;
        padding: 10px;
        .text-description {
          order: 2;
          p {
            display: none;
          }
          .text-button-container {
            display: flex;
            justify-content: center;
            .main-button {
              font-size: 1rem;
              height: 42px;
              width: 164px;
            }
          }
        }
        .text-title-container {
          order: 1;
          position: static;
          margin: 0px;
          line-height: normal;
          width: 100%;
          flex-flow: column wrap;
          justify-content: center;
          align-items: flex-start;
          h2 {
            font-size: 1.6rem;
            margin: 0rem;
          }

          :nth-child(2) {
            margin-left: 0rem;
          }
        }
      }
    }
  }
  .image-centered {
    position: relative;
    img {
      display: none;
    }
  }
}

@media all and (max-width: 450px) {
  .showcase {
    .showcase-wrapper {
      .text-image-container {
        .text-description {
          .text-button-container {
            .main-button {
              font-size: 0.8rem;
              height: 42px;
              width: 125px;
            }
          }
        }
        .text-title-container {
          h2 {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
</style>