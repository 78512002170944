<template>
  <div id="servicios">
    <h1 class="page-title">Servicios</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@media (min-width: 300px) and (max-width: 979px) {
}
</style>