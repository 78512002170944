<template>
  <h1 class="page-title">Contacto</h1>
  <div id="contact">
    <!-- CONTAINER 1 -->
    <div id="form-container" data-aos="flip-up">
      <div class="error-info" v-if="whiteSpaces">
        <p>Hay campos con espacios en blanco en el formulario</p>
      </div>
      <form @submit.prevent="submitForm($event)">
        <h2 class="form-title">Formulario de contacto</h2>
        <!-- DATOS PERSONALES -->
        <fieldset>
          <legend>Datos personales</legend>
          <BaseInput
            label="Nombre*"
            type="text"
            placeholder="Escribe tu nombre"
            required="true"
            v-model="contactValues.nombre"
          />

          <BaseInput
            label="Apellidos*"
            type="text"
            placeholder="Escribe tus apellidos"
            required="true"
            v-model="contactValues.apellidos"
            error=""
          />

          <BaseInput
            label="Email*"
            type="email"
            placeholder="Escribe tu correo electrónico"
            required="true"
            v-model="contactValues.email"
            error=""
          />
        </fieldset>

        <!-- ASUNTO -->
        <fieldset>
          <legend>Asunto</legend>
          <BaseSelect
            label="Por favor, selecciona un asunto del desplegable*"
            :options="asuntos"
            defaultValue="Selecciona una opción"
            required="true"
            v-model="contactValues.asunto"
          />
        </fieldset>

        <!-- MENSAJE -->
        <fieldset>
          <legend>Mensaje*</legend>
          <textarea
            v-model="contactValues.message"
            maxlength="2000"
            required="true"
            placeholder="Mensaje de su consulta"
          ></textarea>
        </fieldset>

        <!-- POLÍTICA PRIVACIDAD DE DATOS -->
        <fieldset>
          <legend>Política de privacidad</legend>
          <BaseCheckBox
            required="true"
            v-model="contactValues.politicaPrivacidad"
          />
          <label for=""
            >He leído y acepto la
            <router-link to="/privacy" target="_blank"
              >Politica de privacidad</router-link
            >*</label
          >
        </fieldset>

        <!-- Submit -->
        <button type="submit">Enviar</button>
      </form>
    </div>

    <!-- CONTAINER 2 -->
    <div id="image-container" data-aos="flip-up">
      <img
        class="image-contact"
        src="@/assets/images/Galeria/Contact/andreu_contacto.jpg"
        alt="Imagen de Andreu Gual con un cliente"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseCheckBox from "@/components/BaseCheckBox.vue";
import emailjs from "emailjs-com";

export default {
  components: { BaseInput, BaseSelect, BaseCheckBox },
  data() {
    return {
      asuntos: [
        "Comunión",
        "Boda",
        "Book",
        "Sesión",
        "Taller",
        "Curso",
        "Sesión fotográfica privada",
        "Clase 1to1",
        "Producto",
        "Otros",
      ],
      contactValues: {
        nombre: "",
        apellidos: "",
        email: "",
        asunto: "",
        politicaPrivacidad: false,
        message: "",
      },

      whiteSpaces: false,
    };
  },
  methods: {
    // TO DO: SENT FORM INFO TO MAIL
    submitForm() {
      emailjs
        .sendForm(
          "service_yne5u4p",
          "template_4m67mmg",
          this.contactValues,
          "user_fDt4MIoIxUbEknZKChWKp"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.status, result.text);
          },
          (error) => {
            console.log("FAILED...", error);
          }
        );
    },
  },
  // FORM VALIDATE
  validateForm() {
    console.log(`Nombre`, this.contactValues.nombre);
    console.log(`Apellidos`, this.contactValues.apellidos);
    console.log(`Email`, this.contactValues.email);
    console.log(`Asunto`, this.contactValues.asunto);
    console.log(
      `Política privacidad de datos`,
      this.contactValues.politicaPrivacidad
    );
    console.log("Mensaje", this.message);

    if (
      this.contactValues.nombre.trim() == "" ||
      this.contactValues.apellidos.trim() == "" ||
      this.contactValues.email.trim() == "" ||
      this.contactValues.message.trim() == ""
    ) {
      this.whiteSpaces = true;
      return false;
    } else {
      this.whiteSpaces = false;
      return true;
    }
  },
};
</script>

<style lang="scss" scoped>
// MAIN CONTACT CONTAINER
#contact {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin-bottom: 50px;

  #form-container,
  #image-container {
    flex-basis: 42%;
  }

  // SECOND CHILD CONTACT CONTAINER
  #form-container {
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 8px;

    form {
      background-color: rgb(238, 238, 241);
      padding: 50px;

      .form-title {
        text-align: center;
        margin-bottom: 50px;
        border-bottom: 2px solid #d9d9d9;
        padding-bottom: 16px;
        font-size: 1.6rem;
        font-weight: normal;
      }

      fieldset {
        border: 0;
        margin: 0;
        padding: 0;
        margin-bottom: 25px;

        legend {
          font-size: 26px;
          margin-bottom: 20px;
        }

        .error-info {
          background-color: yellow;
          margin: 11px;
          padding: 11px;
          text-align: center;
          font-size: 18px;
          border-radius: 8px;
        }

        textarea {
          width: 100%;
          height: 250px;
          border: none;
          resize: vertical;
          padding: 5px;
          font-size: 1rem;

          &:focus,
          &:focus:hover {
            outline: none;
            border: 2px solid #ccc;
            padding: 4px;
          }
        }
      }
      button[type="submit"] {
        width: 150px;
        padding: 12px;
        border: none;
        border-radius: 4px;
        font-size: 18px;
        outline: none;
        background-color: #333;
        color: #fff;

        &:hover {
          cursor: pointer;
          background-color: #333333bf;
        }
      }
    }
  }

  // FIRST CHILD CONTACT CONTAINER
  #image-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .image-contact {
      width: 800px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      transition: all 300ms;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        transform: scale(1.05);
      }
    }
  }
}

#result {
  margin: 50px;
}

@media (min-width: 300px) and (max-width: 1500px) {
  #contact {
    #form-container,
    #image-container {
      flex-basis: 82%;
    }

    #form-container {
      margin-bottom: 50px;
    }
  }
}

@media (min-width: 300px) and (max-width: 979px) {
  #contact {
    #form-container,
    #image-container {
      flex-basis: 100%;
    }

    #form-container {
      order: 2;
      border-radius: 0px !important;
      box-shadow: none !important;

      form {
        margin: 20px;
        padding: 10px !important;
        background-color: transparent !important;

        fieldset {
          legend {
            text-align: center;
          }

          textarea {
            border: 1px solid #ccc;
            padding: 0px;
          }
        }

        button[type="submit"] {
          display: block;
          width: 100%;
          margin-top: 50px;
        }
      }
    }

    #image-container {
      order: 1;
      .image-contact {
        width: 100%;
        border-radius: 0px;
        box-shadow: none;
      }
    }
  }
}
</style>