<template>
  <div id="political-privacy">
    <h1 class="page-title">Política de privacidad</h1>

    <h2>Nota importante sobre el tratamiento de datos y Google Analytics</h2>
    <p>
      Esta página web utiliza Google Analytics, un servicio de análisis web de
      Google Ireland Limited. En caso de que el responsable del tratamiento de
      datos sobre esta página web resida en un país fuera del Espacio Económico
      Europeo o Suiza, el tratamiento de datos de Google Analytics se realiza a
      través de Google LLC. Google LLC y Google Ireland Limited serán referidos
      en lo sucesivo como “Google”.
      <br /><br />
      Google Analytics utiliza las llamadas “Cookies”, archivos de texto que se
      almacenan en el dispositivo del visitante de la página y que posibilitan
      un análisis del uso de la página web del visitante. La información
      generada por la Cookie sobre el uso de esta página web (incluyendo la
      dirección IP acortada) se transmite y se almacena normalmente por Google.
      <br /><br />
      Google Analytics se utiliza en esta página web únicamente con la extensión
      "_anonymizeIp()". Esta extensión asegura la anonimización de la dirección
      IP mediante el acortamiento y excluye cualquier referencia personal
      directa. Mediante la extensión, la dirección IP del visitante es acortada
      previamente por Google dentro de los países miembros de la Unión Europea o
      en otros países parte del acuerdo del Espacio Económico Europeo. Solo en
      casos excepcionales se transmite la dirección IP completa a un servidor de
      Google en EE.UU. y, una vez allí, es acortada. La dirección IP transmitida
      por el navegador correspondiente, en el marco de las acciones de Google
      Analytics, no se combina con otros datos de Google.
      <br /><br />
      En nombre del dueño de la página, Google utilizará la información generada
      para analizar el uso de la página, realizar informes sobre las actividades
      de la página web y para llevar a cabo otros servicios relacionados con el
      uso de Internet o de la página web para el dueño de la página (Art. 6
      apart. 1 letra f RGPD). El interés legítimo del tratamiento de datos
      radica en la optimización de la página web, el análisis del uso de la
      página web y la adaptación del contenido. Los intereses del usuario están
      garantizados adecuadamente mediante la pseudominización de sus datos.
      <br /><br />
      Google LLC garantiza el mantenimiento de un nivel adecuado de protección
      de datos en base a las cláusulas contractuales estándares europeas. La
      información enviada y vinculada a las cookies de Google Analyticas, p.ej.,
      las ID de usuarios o los identificadores de publicidad se eliminarán
      automáticamente tras 50 meses. La supresión de los datos que alcanzan su
      período de retención se realiza de manera automática una vez al mes.
      <br /><br />
      El visitante de la página web puede rechazar el uso de cookies
      configurando los ajustes correspondientes en su navegador. Asimismo, el
      visitante puede impedir la recopilación de información mediante cookies
      (incluida su dirección IP) y su procesamiento descargando e instalando el
      siguiente plugin en su navegador:
      <a href="https://tools.google.com/dlpage/gaoptout" target="_blank"
        >https://tools.google.com/dlpage/gaoptout</a
      >
      <br /><br />
      El visitante de la página puede desactivar el uso de datos de Google
      Analytics haciendo clic en este enlace. A continuación, se activará una
      Opt-Out-Cookie que bloqueará el uso de sus datos al visitar esta página
      web.
      <br /><br />
      Más información sobre el uso de datos por Google, las opciones de
      configuración y anulación se puede encontrar en la Política de Privacidad
      de Google
      <a href="https://policies.google.com/privacy" target="_blank"
        >https://policies.google.com/privacy</a
      >, así como en los Ajustes para la publicidad de Google
      <a href="https://adssettings.google.com/authenticated" target="_blank"
        >https://adssettings.google.com/authenticated</a
      >.
    </p>
    <h2>reCAPTCHA</h2>
    <p>
      Utilizamos el servicio reCAPTCHA de Google LLC (Google) para proteger los
      datos enviados a través de los formularios de las páginas de nuestros
      usuarios. Este servicio permite diferenciar los datos enviados por
      personas de los mensajes automatizados y supone la transmisión de la
      dirección IP y otros datos requeridos por Google para el uso del servicio
      reCAPTCHA. Con tal finalidad, los datos serán transmitidos a Google para
      poder ser utilizados. La dirección IP será encriptada por Google en los
      estados miembros de la Unión Europea así como en los estados asociados al
      espacio económico europeo. Solo en algunos casos excepcionales, las
      direcciones IP serán transmitidas al servidor de Google en los Estados
      Unidos para su encriptación. En nombre del propietario de este sitio web,
      Google va a utilizar estas informaciones para evaluar el uso del servicio
      por parte de los usuarios. La dirección IP transmitida por reCAPTCHA se
      mantendrá separada de otros datos de Google. Para estos datos es válida la
      política de privacidad de Google. Todos los detalles acerca de la política
      de privacidad de Google se pueden leer en la página
      <a href="https://policies.google.com/privacy?hl=es" target="_link"
        >https://policies.google.com/privacy?hl=es</a
      >
      Al utilizar el servicio reCAPTCHA, el usuario consiente el tratamiento de
      los datos sobre él de los que Google dispone en la forma y propósitos
      arriba establecidos.
    </p>
  </div>
</template>

<script>
export default {
  name: "Privacy",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#political-privacy {
  padding: 5vh 0;
  padding-left: 20%;
  padding-right: 20%;

  h1 {
    color: #333;
    margin: 0px 0px 50px 0px;
  }

  h2 {
    font-size: 30px;
    text-align: center;
    color: #009ded;
    padding: 10px;
  }

  a {
    color: #009ded;
  }

  p {
    font-size: 18px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  :nth-child(4) {
    margin-top: 50px;
  }
}
</style>