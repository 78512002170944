import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './assets/css/main.scss'
import VueGoogleMaps from '@fawmi/vue-google-maps'

// CSS Library
AOS.init();

// jQuery
window.$ = window.jQuery = require('jquery');

const app = createApp(App);

// Google Maps key
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDdq2phHQaiyhxJdPQYeNOQhJj1Q_mqKgc',
    }
})

app.use(router);
app.mount("#app");