<template>
  <footer class="footer">
    <!-- Columna izquierda -->
    <div class="left-footer footer-column">
      <div class="redes-sociales">
        <h2>Redes Sociales</h2>
        <div class="adress">
          <p>📸 Redes sociales donde publico más fotografías</p>
        </div>
        <ul>
          <li>
            <a href="https://www.instagram.com/andreugual/" target="blank"
              ><i class="fab fa-instagram"></i> Instragram</a
            >
          </li>
          <li>
            <a href="https://www.facebook.com/andreu.gualroca" target="blank"
              ><i class="fab fa-facebook"></i> Facebook</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Columna central -->
    <div class="center-footer footer-column">
      <h2>Contacto</h2>
      <div class="adress">
        <a href="https://wa.me/+34685821200" target="_blank">
          <i class="fab fa-whatsapp"></i>
          685821200
        </a>
        <a id="mailTo" href="mailto:andreu.gualroca@gmail.com"
          >📧 andreu.gualroca@gmail.com</a
        >
      </div>
    </div>

    <!-- Columna derecha -->
    <div class="right-footer footer-column">
      <h2>Estudio fotográfico en Tarragona</h2>
      <div class="adress">
        <p>🏠 Calle Macià Mallol i Bosch, 43005 Tarragona</p>
      </div>
      <GMapMap
        :center="center"
        :zoom="17"
        map-type-id="terrain"
        style="width: 500px; height: 281px"
        id="googleMapTemplate"
      >
        <GMapCluster>
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center = m.position"
          />
        </GMapCluster>
      </GMapMap>
    </div>

    <!-- Other data -->
    <div id="footer-data">
      <router-link to="/privacy">Política de privacidad</router-link>
      <router-link to="/cookies">Política de cookies</router-link>
      <router-link to="/site">Mapa del sitio</router-link>
      <router-link to="/copyrithgt"
        >© Fotografía Andreu Gual, todos los derechos reservados</router-link
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      center: { lat: 41.1225464738382, lng: 1.2401585541310352 },
      markers: [
        {
          position: {
            lat: 41.1225464738382,
            lng: 1.2401585541310352,
          },
        },
      ],
      contactImage: require("@/assets/images/tarjeta_presentacio.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  background-color: #333;

  .footer-column {
    text-align: center;
    color: #ccc;
    margin-top: 60px;
    width: 33%;

    h2 {
      font-size: $title-font-size;
    }
  }

  // Columna izquierda
  .redes-sociales {
    ul {
      text-align: left;
      margin-left: 150px;
      margin-top: 70px;

      :nth-child(1) a:hover i {
        color: rgb(235, 133, 150);
      }

      :nth-child(2) a:hover i {
        color: #1877f2;
      }

      :nth-child(3) a:hover i {
        color: #0270ad;
      }

      :nth-child(4) a:hover i {
        color: #009ded;
      }

      li {
        margin: 20px;
        list-style: none;
        font-size: $text-font-size;

        a {
          text-decoration: none;
          color: dodgerblue;
          display: flex;
          align-items: center;
        }

        a:hover {
          color: #009eeda9;
        }

        i {
          font-size: 40px;
          color: #ccc;
          margin-right: 20px;
        }
      }
    }
  }

  // Columna central
  .center-footer {
    .adress {
      a {
        text-decoration: none;
        color: #ccc;
        margin-bottom: 7px;

        i {
          font-size: 20px;
          color: #25d366;
        }
      }
    }
    .image-contact {
      img {
        display: block;
        height: 100%;
        margin: auto;
      }
    }
  }

  // Columna derecha
  .right-footer {
    #googleMapTemplate {
      display: block;
      text-align: center;
      margin: auto;
      margin-top: 50px;
    }
    .vue-map-container {
      margin: auto;
      margin-top: 54px;
      width: 80%;
      height: 282px;
    }
  }

  // DIV DATA
  #footer-data {
    width: 100%;
    display: flex;
    flex-flow: wrap row;
    justify-content: space-evenly;
    margin: 100px 0px 50px 0px;
    a,
    p {
      color: #ccc;
      text-decoration: none;
    }
  }
}

.adress {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

@media (min-width: $tablet-query) and (max-width: $laptop-query) {
  .right-footer {
    .vue-map-container {
      width: 100% !important;
    }
  }
}

@media (min-width: 300px) and (max-width: $tablet-query) {
  .footer {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    .footer-column {
      width: 100%;
    }

    .left-footer .redes-sociales ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      align-items: center;
      margin: 0px;
      padding: 0px;

      li {
        width: 30%;
      }

      a {
        font-size: 22px;
        display: block;
        text-align: center;
      }

      li i {
        display: block;
        text-align: center;
        margin: 0px;
      }
    }

    .center-footer {
      .image-contact {
        img {
          width: 85%;
        }
      }
    }

    .right-footer {
      .vue-map-container {
        width: 85% !important;
      }
    }

    #footer-data {
      flex-flow: row wrap;
      align-items: center;
      margin: 40px 0px 40px 0px;

      a {
        margin: 10px;
        text-align: center;
      }
    }
  }
}
</style>