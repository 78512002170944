<template>
  <section v-if="commentsLenght > 0" class="opinions">
    <h2 v-if="title" class="opiniones-title">{{ title }}</h2>
    <div class="opinions-container">
      <div
        class="opinion-card"
        v-for="comment in comments"
        :key="comment"
        data-aos="flip-up"
      >
        <p class="opinion-card-name">{{ comment.nombre }}</p>
        <p class="opinion-card-fecha">{{ comment.fecha }}</p>
        <p class="opinion-card-comentario">"{{ comment.comentario }}"</p>
      </div>
    </div>
    <button class="opinions-button main-button" @click="redirectOpiniones">
      Ver más opiniones
    </button>
  </section>
</template>

<script>
import EventService from "@/services/EventService";
export default {
  name: "Opiniones",
  props: {
    title: {
      type: String,
      required: false,
    },
    section: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      comments: [],
    };
  },
  methods: {
    redirectOpiniones() {
      console.log("a");
      this.$router.push("/opiniones");
    },
  },
  computed: {
    commentsLenght() {
      return this.comments.length;
    },
  },
  created() {
    EventService.getComments(this.section)
      .then((response) => {
        if (response.data.comments) {
          this.comments = response.data.comments;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.opinions {
  margin: auto;
  width: 100%;

  .opiniones-title {
    text-align: center;
    margin-bottom: 40px;
  }
  .opinions-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;

    .opinion-card {
      flex-basis: 40%;
      margin-bottom: 40px;
      padding: 20px;
      border-radius: 5px;
      background-color: $grey-color;
      box-shadow: $box-shadow-page;

      .opinion-card-name {
        font-weight: bold;
      }
      .opinion-card-fecha {
        color: #ccc;
        font-size: 12px;
      }
    }
  }

  .opinions-button {
    display: block;
    margin: 20px auto;
  }
}

/** TO DO: CAROUSEL OF OPINIONS */
@media all and (max-width: $tablet-query) {
  .opinions {
    width: 90%;

    .opinions-container {
      flex-flow: column wrap;

      .opinion-card {
        flex-basis: 100%;
        margin: 0px 0px 20px 0px;
        border-radius: 5px;
      }
    }

    .opinions-button {
      display: block;
      margin: 10px auto;
    }
  }
}
</style>