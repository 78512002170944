import axios from 'axios';

const apiInstagram = axios.create({
    baseURL: 'https://graph.instagram.com/me',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
});

export default {
    getLatest(fields, token) {
        return apiInstagram.get('media?fields=' + fields + '&access_token=' + token);
    }
}