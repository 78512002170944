import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Galerias from '@/views/Galerias.vue'
import Bodas from '@/views/Bodas.vue'
import Servicios from '@/views/Servicios.vue'
import Comuniones from '@/views/Comuniones.vue'
import Cursos from '@/views/Cursos.vue'
import Contacto from '@/views/Contacto.vue'
import Opiniones from '@/views/Opiniones.vue'
import Error from '@/components/Error'
import Privacy from '@/components/Privacy'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/galerias',
    name: 'Galerias',
    component: Galerias
  },
  {
    path: '/comuniones',
    name: 'Comuniones',
    component: Comuniones
  },
  {
    path: '/bodas',
    name: 'Bodas',
    component: Bodas,
    props: true
  },
  {
    path: '/cursos',
    name: 'Cursos',
    component: Cursos
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: Servicios
  },
  {
    path: '/opiniones',
    name: 'Opiniones',
    component: Opiniones
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    component: Error
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  },
})


export default router;
