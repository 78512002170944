<template>
  <header>
    <nav id="main-nav" :class="getScrollY > 600 ? 'main-nav-fixed' : ''">
      <router-link to="/galerias">GALERÍAS</router-link>
      <router-link to="/cursos">CURSOS</router-link>
      <router-link to="/"
        ><img
          class="image-nav"
          src="../assets/images/logo.jpg"
          alt="Fotografía Andreu Gual"
      /></router-link>
      <router-link to="/servicios">SERVICIOS</router-link>
      <router-link to="/contacto">SOBRE MÍ</router-link>
    </nav>
    <!-- MOBILE  -->
    <div id="main-nav-device" style="display: none">
      <router-link to="/"
        ><img src="../assets/images/logo.jpg" alt="Fotografía Andreu Gual"
      /></router-link>
      <!-- HAMBURGUER -->
      <div
        class="hamburger"
        @click="toggleHamburger"
        :class="hamburgerOpen ? 'hamburger-is-open active' : ''"
      >
        <div class="hamburger_item hamburger_item_first"></div>
        <div class="hamburger_item hamburger_item_middle"></div>
        <div class="hamburger_item hamburger_item_last"></div>
      </div>
      <nav
        id="mobile-container"
        :style="{ height: computedHeight, opacity: computedOpacity }"
      >
        <router-link to="/galerias">GALERÍA</router-link>
        <router-link to="/cursos">CURSOS</router-link>
        <router-link to="/servicios">SERVICIOS</router-link>
        <router-link to="/contacto">SOBRE MÍ</router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      hamburgerOpen: false,
      height: "0px",
      opacity: "0",
      scrollY: 0,
    };
  },

  computed: {
    computedHeight() {
      return this.height;
    },
    computedOpacity() {
      return this.opacity;
    },
    getScrollY() {
      return this.scrollY;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    toggleHamburger() {
      let container = document.getElementById("mobile-container");
      let containerMaxHeight = container.scrollHeight + "px";

      this.hamburgerOpen = this.hamburgerOpen == true ? false : true;

      if (this.hamburgerOpen) {
        this.opacity = "1";
        this.height = containerMaxHeight;
      } else {
        this.opacity = "0";
        this.height = "0px";
      }
    },
    handleScroll() {
      this.scrollY = window.scrollY;
    },
  },
};
</script>

<style lang="scss" scoped>
#main-nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  min-height: 100px;
  width: 100%;
  background-color: #fff;
  z-index: 50 !important;
  padding-top: 10px;
  padding-bottom: 10px;

  a {
    text-decoration: none;
    color: $main-color;
    height: 100%;
    text-transform: uppercase;
    font-size: 1em;

    &:hover {
      text-decoration: underline;
    }
  }

  .image-nav {
    width: 200px;
  }
}

#main-nav.main-nav-fixed {
  position: fixed;
  top: 0px;
  padding: 0px;
  box-shadow: 0px 1px 20px 0px #e2e2e2;
  transition: all 1s;
  min-height: 0px;

  .image-nav {
    width: 100px;
    transition: all 1s;
  }
}

@media (min-width: 300px) and (max-width: 979px) {
  #main-nav {
    display: none;
    width: 100%;
  }

  #main-nav-device {
    display: block !important;
    a {
      display: block;
      text-align: center;
      color: $main-color;
    }

    #mobile-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      transition: all 1s;

      a {
        text-decoration: none;
        width: 100%;
        height: 50px;
        vertical-align: center;
        line-height: 50px;
        border-top: 1px solid rgb(245, 245, 245);
        border-bottom: 1px solid rgb(245, 245, 245);
      }
    }

    .showMobileContainer {
      display: block;
    }
  }
}

.hamburger {
  height: 30px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50px;
  right: 20px;

  &:hover {
    cursor: pointer;
  }

  &_item {
    height: 2px;
    width: 100%;
    background: $main-color;
    transition: transform 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
      opacity 300ms linear;

    &_first {
      .hamburger-is-open & {
        transform: translate(0, 14px) rotate(44deg);
      }
    }

    &_middle {
      .hamburger-is-open & {
        opacity: 0;
      }
    }

    &_last {
      .hamburger-is-open & {
        transform: translate(0, -14px) rotate(-44deg);
      }
    }
  }
}
</style>