import axios from 'axios';

require("dotenv").config();

const apiClient = axios.create({
    baseURL: "http://localhost:3000",        // My server URL
    withCredentials: false,                  // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
});

export default {
    getComments(section) {
        return apiClient.get('/get-comments/' + section);
    }
}