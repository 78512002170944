<template>
  <h1 class="page-title">{{ title }}</h1>
  <CollagePresentation :images="images"></CollagePresentation>
</template>

<script>
import CollagePresentation from "@/components/CollagePresentation.vue";

export default {
  name: "Galerias",
  components: { CollagePresentation },
  data() {
    return {
      title: "Galerías",
      images: [
        {
          id: 1,
          title: "BODAS",
          src: require("@/assets/images/Home/bodas.jpg"),
          route: "bodas",
        },
        {
          id: 2,
          title: "COMUNIONES",
          src: require("@/assets/images/Home/comuniones.jpg"),
          route: "comuniones",
        },
        {
          id: 3,
          title: "EVENTOS",
          src: require("@/assets/images/Home/eventos.jpg"),
          route: "eventos",
        },
        {
          id: 4,
          title: "PRODUCTO Y EMPRESAS",
          src: require("@/assets/images/Home/producto.jpg"),
          route: "producto-y-empresas",
        },
        {
          id: 5,
          title: "FORMACIÓN",
          src: require("@/assets/images/Home/formacion.jpg"),
          route: "cursos",
        },
        {
          id: 6,
          title: "SERVICIOS",
          src: require("@/assets/images/Home/servicios.jpg"),
          route: "servicios",
        },
        {
          id: 1,
          title: "BODAS",
          src: require("@/assets/images/Home/bodas.jpg"),
          route: "bodas",
        },
        {
          id: 2,
          title: "COMUNIONES",
          src: require("@/assets/images/Home/comuniones.jpg"),
          route: "comuniones",
        },
        {
          id: 3,
          title: "EVENTOS",
          src: require("@/assets/images/Home/eventos.jpg"),
          route: "eventos",
        },
        {
          id: 4,
          title: "PRODUCTO Y EMPRESAS",
          src: require("@/assets/images/Home/producto.jpg"),
          route: "producto-y-empresas",
        },
        {
          id: 5,
          title: "FORMACIÓN",
          src: require("@/assets/images/Home/formacion.jpg"),
          route: "formacion",
        },
        {
          id: 6,
          title: "SERVICIOS",
          src: require("@/assets/images/Home/servicios.jpg"),
          route: "servicios",
        },
      ],
    };
  },
};
</script>