  <!-- Shows the gallery with 3 images per row with 500 width x 450 height px size each one -->
<template>
  <section class="galery-content">
    <div class="galery-wrapper">
      <!-- Title -->
      <h1 class="page-title" v-if="title">{{ title }}</h1>
      <!-- Cards -->
      <div class="gallery-cards">
        <div
          class="gallery-card"
          v-for="image in images"
          :key="image"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <router-link :to="image.route" style="text-decoration: none">
            <!-- IMAGE CONTAINER -->
            <div class="card-image-container">
              <img :src="image.src" :alt="image.alt" />
            </div>
            <!-- TEXT CONTAINER -->
            <div class="card-text">
              <span v-if="image.title">{{ image.title }} </span>
              <span v-if="image.alt">{{ image.alt }} </span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CollagePresentation",
  props: {
    images: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.galery-content {
  width: 100%;
  overflow: hidden;
  margin: 40px 0px;
  background-color: #f2edf0;
  display: flex;
  justify-content: center;
  align-items: center;

  .galery-wrapper {
    margin: 20px 0px;

    .gallery-cards {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      align-items: center;

      .gallery-card {
        flex-basis: 30%;
        padding: 20px;
        margin-bottom: 35px;

        :last-child {
          margin-bottom: 0px;
        }

        &:hover {
          cursor: pointer;
          filter: grayscale(100%);
        }

        .card-image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          overflow: hidden;
          box-shadow: 0px 0px 1px #ccc;
          border-radius: 2px;
          transition: all 300ms;
          margin: auto;

          img {
            width: 100%;
            transition: all 500ms;
          }

          img:hover {
            transform: scale(1.05);
          }
        }

        .card-text {
          position: relative;
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          z-index: 5;
          color: #fff;
          margin-top: -83px;
          padding: 10px;
          background-color: $main-shadowed-color;

          :first-child {
            font-size: 24px;
            margin-bottom: 5px;
          }

          :last-child {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1199px) {
  .galery-content {
    .galery-wrapper {
      .gallery-cards {
        .gallery-card {
          flex-basis: 30%;
          padding: 0px;
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
  .galery-content {
    .galery-wrapper {
      .gallery-cards {
        .gallery-card {
          flex-basis: 45%;
          padding: 0px;
          margin-bottom: 35px;
          .card-text {
            margin-top: -50px;
            padding: 5px;
            background-color: $main-shadowed-color;

            :first-child {
              font-size: 10px;
              margin: auto;
            }

            :last-child {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
</style>