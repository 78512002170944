<template>
  <section class="collage-multiple">
    <h2 v-if="title" class="page-title">{{ title }}</h2>
    <div
      class="box"
      v-for="(image, index) in images"
      :key="image"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <a v-if="index < 10" :href="image.permalink" target="_blank">
        <div class="img-wrapper">
          <img
            class="image-item"
            :src="image.media_url"
            alt="Collage imágenes de Instagram"
            loading="lazy"
          />
        </div>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    images: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.collage-multiple {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;

  h2 {
    flex-basis: 100%;
    text-align: center;
    padding: 0px 0px 20px 0px;
  }

  .box {
    flex-basis: calc(20% - 10px);
    flex-grow: 1;
    flex-shrink: 1;
    margin: 5px;

    .img-wrapper {
      overflow: hidden;
      padding-bottom: 114.5%;
      position: relative;
    }

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 300px) and (max-width: 979px) {
  .collage-multiple {
    width: 100%;

    .box {
      flex-basis: calc(50% - 10%);
    }
  }
}
</style>