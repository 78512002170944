<template>
  <div id="comuniones">
    <h2 class="page-title">Comuniones</h2>
    <CollageDouble :images="imagesCollageDouble"></CollageDouble>
    <CollageMultiple
      :images="imagesCollageMultiple"
      title="Ejemplo título comuniones"
    ></CollageMultiple>
    <CollageDouble :images="imagesCollageDouble"></CollageDouble>
    <CollageMultiple
      :images="imagesCollageMultiple"
      title="Comunión de Pepe"
    ></CollageMultiple>
    <CollageDouble :images="imagesCollageDouble"></CollageDouble>
  </div>
</template>

<script>
import CollageDouble from "@/components/CollageDouble.vue";
import CollageMultiple from "@/components/CollageMultiple.vue";
export default {
  components: { CollageDouble, CollageMultiple },
  data() {
    return {
      imagesCollageDouble: [
        {
          id: 1,
          title: "Boda1",
          alt: "Boda1",
          src: "https://i.wifegeek.com/200426/5fa51df3.jpg",
        },
        {
          id: 2,
          title: "Boda2",
          alt: "Boda2",
          src: "https://i.wifegeek.com/200426/5ce1e1c7.jpg",
        },
      ],
      imagesCollageMultiple: [
        "https://i.wifegeek.com/200426/f9459c52.jpg",
        "https://i.wifegeek.com/200426/5ce1e1c7.jpg",
        "https://i.wifegeek.com/200426/5fa51df3.jpg",
        "https://i.wifegeek.com/200426/663181fe.jpg",
        "https://i.wifegeek.com/200426/2d110780.jpg",
        "https://i.wifegeek.com/200426/e73cd3fa.jpg",
        "https://i.wifegeek.com/200426/15160d6e.jpg",
        "https://i.wifegeek.com/200426/d0c881ae.jpg",
        "https://i.wifegeek.com/200426/a154fc3d.jpg",
        "https://i.wifegeek.com/200426/71d3aa60.jpg",
        "https://i.wifegeek.com/200426/d17ce9a0.jpg",
        "https://i.wifegeek.com/200426/7c4deca9.jpg",
        "https://i.wifegeek.com/200426/64672676.jpg",
        "https://i.wifegeek.com/200426/de6ab9c6.jpg",
        "https://i.wifegeek.com/200426/d8bcb6a7.jpg",
        "https://i.wifegeek.com/200426/4085d03b.jpg",
        "https://i.wifegeek.com/200426/177ef44c.jpg",
        "https://i.wifegeek.com/200426/d74d9040.jpg",
        "https://i.wifegeek.com/200426/81e24a47.jpg",
        "https://i.wifegeek.com/200426/43e2e8bb.jpg",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 300px) and (max-width: 979px) {
}
</style>