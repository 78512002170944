<template>
  <Header />
  <!-- ROUTES VIEW. -->
  <router-view />
  <Footer />
  <ScrollToTop />
</template>

<script>
import Header from "./components/Header.vue";
import ScrollToTop from "./components/ScrollToTop.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "app",
  components: { Header, ScrollToTop, Footer },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>
