<template>
    <div id="error-content">
        <h2>Página no encontrada</h2>
        <h3>¡Ups! La página a la que intentas acceder no existe 😃</h3>
    </div>
</template>

<script>
    export default {
        name: "Error"
    }
</script>

<style lang="scss" scoped>
    #error-content {
        padding: 25vh 0;
        text-align: center;
    }
</style>