<template>
  <div id="home">
    <Carousel :imagesToShow="carouselImages" />
    <CollagePresentation :images="imagesCollagePresentation" />
    <Service1 />
    <Service2 />
    <Service3 />
    <Opiniones title="Qué opinan sobre mí" section="Home" />
    <Instagram
      fields="id,caption,media_url,media_type,permalink"
      token="IGQVJVeEJEc0RCWHFackJEd1NLRlFQWG42ZAkJJTkY5VU0yX0VtbS1fODhpVjlKcmR4Y2JCZAXEtQi1wT0RSd3d1eXVUcTlyeUJIR2pBZAV9lRnRjQXZA0T2x6UHZAxWlF1aGd5SDdUbFFR"
    />
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import Service1 from "@/components/Service-Model-1.vue";
import Service2 from "@/components/Service-Model-2.vue";
import Service3 from "@/components/Service-Model-3.vue";
import CollagePresentation from "@/components/CollagePresentation.vue";
import Opiniones from "@/components/Opiniones.vue";
import Instagram from "@/components/Instagram.vue";

export default {
  name: "Home",
  components: {
    Carousel,
    Service1,
    Service2,
    Service3,
    CollagePresentation,
    Opiniones,
    Instagram,
  },
  data() {
    return {
      // CARROUSEL
      carouselImages: [
        require("@/assets/images/Carousel_Home/01.jpg"),
        require("@/assets/images/Carousel_Home/02.jpg"),
        require("@/assets/images/Carousel_Home/03.jpg"),
        require("@/assets/images/Carousel_Home/05.jpg"),
        require("@/assets/images/Carousel_Home/07.jpg"),
        require("@/assets/images/Carousel_Home/08.jpg"),
        require("@/assets/images/Carousel_Home/09.jpg"),
        require("@/assets/images/Carousel_Home/10.jpg"),
        require("@/assets/images/Carousel_Home/11.jpg"),
        require("@/assets/images/Carousel_Home/12.jpg"),
        require("@/assets/images/Carousel_Home/13.jpg"),
        require("@/assets/images/Carousel_Home/14.jpg"),
        require("@/assets/images/Carousel_Home/15.jpg"),
        require("@/assets/images/Carousel_Home/16.jpg"),
        require("@/assets/images/Carousel_Home/17.jpg"),
      ],

      // SERVICIOS
      showcaseServices2: {
        id: 2,
        title: "Cursos i talleres amplia conocimientos",
        description:
          "Si quieres iniciarte en el mundo de la fotografía o perfeccionar tu técnica , te ofrecemos diversos cursos y talleres personalizados desde el funcionamiento de tu cámara hasta la edición final.",
        link: "/cursos",
        image1: require("@/assets/images/Showcase/showcase2_1.jpg"),
        image2: require("@/assets/images/Showcase/showcase2_2.jpg"),
      },
      showcaseServices3: {
        id: 3,
        title: "Servicios fotográficos",
        description:
          "Desde tus impresiones a máxima calidad o la limpieza del sensor de tu cámara. Digitalizamos  negativos y diapositivas o transferimos cualquier soporte de video analógico a digital. Te damos solución a cualquier otro servicio que puedas precisar, todo sin que tu material salga de nuestro estudio.",
        link: "/servicios",
        image1: require("@/assets/images/Showcase/servicios.png"),
      },

      imagesCollagePresentation: [
        {
          id: 1,
          title: "BODAS",
          src: require("@/assets/images/Home/bodas.jpg"),
          route: "bodas",
        },
        {
          id: 2,
          title: "COMUNIONES",
          src: require("@/assets/images/Home/comuniones.jpg"),
          route: "comuniones",
        },
        {
          id: 3,
          title: "EVENTOS",
          src: require("@/assets/images/Home/eventos.jpg"),
          route: "eventos",
        },
        {
          id: 4,
          title: "PRODUCTO Y EMPRESAS",
          src: require("@/assets/images/Home/producto.jpg"),
          route: "producto-y-empresas",
        },
        {
          id: 5,
          title: "FORMACIÓN",
          src: require("@/assets/images/Home/formacion.jpg"),
          route: "cursos",
        },
        {
          id: 6,
          title: "SERVICIOS",
          src: require("@/assets/images/Home/servicios.jpg"),
          route: "servicios",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>