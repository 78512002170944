<template>
  <section class="collage-container">
    <h1 v-if="title" class="page-title">{{ title }}</h1>
    <div
      class="images-container"
      v-for="image in images"
      :key="image"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <img :src="image.src" :alt="image.alt" loading="lazy" />
    </div>
  </section>
</template>

<script>
export default {
  props: ["title", "images"],

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.collage-container {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  margin-bottom: 80px;

  .images-container {
    flex-basis: calc(50%);
    display: flex;
    justify-content: center;
    overflow: hidden;
    border: none;
    filter: grayscale(70%);

    img {
      filter: grayscale(20%);
      width: 100%;
    }
  }
}

@media (min-width: 300px) and (max-width: 979px) {
  .collage-container {
    flex-flow: column nowrap;
    width: 100%;

    .images-container {
      flex-basis: 100%;
      margin-bottom: 3px;
    }
  }
}
</style>
