<template>
  <input
    :id="uuid"
    class="checkbox-field"
    type="checkbox"
    v-bind="$attrs"
    :checked="modelValue"
    @change="$emit('update:modelValue', $event.target.checked)"
  />

  <label :for="uuid"> {{ label }} </label>
</template>

<script>
import UniqueID from "@/features/UniqueID.js";
export default {
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uuid = UniqueID().getID();
    return {
      uuid,
    };
  },
};
</script>

<style lang="scss" scoped>
input {
    display: inline-block;
    margin-right: 5px;
    width: 22px;
    height: 22px;

    &:hover {
        cursor: pointer;
    }
}

label {
    margin-top: -5px;
}
</style>