<template>
  <div id="opiniones">
    <h1 class="page-title">Opiniones Fotografía Andreu Gual</h1>
    <p v-if="totalComments > 0">
      Total de comentarios cargados: {{ totalComments }}
    </p>
  </div>
</template>

<script>
import EventService from "../services/EventService.js";

export default {
  name: "Opiniones",

  data() {
    return {
      comments: null,
      totalComments: 0,
    };
  },

  created() {
    // GET ALL COMMENTS
    EventService.getComments("Bodas")
      .then((response) => {
        this.response = response.data.comments;
        this.totalComments = response.data.comments.length;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
</style>