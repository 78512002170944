<template>
  <div
    class="scrollToTop"
    @click="scrollTop"
    v-if="showScroll"
    data-aos="flip-up"
    data-aos-easing="linear"
  >
    <span class="scrollArrowTop"><i class="fas fa-chevron-up"></i></span>
    <img
      class="scrollImage"
      :src="imageScroll"
      alt="Fotografía Andreu Gual Tarragona"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showScroll: false,
      imageScroll: require("@/assets/images/SmallLogo.png"),
    };
  },

  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    handleScroll() {
      const userScrolling = window.scrollY;

      if (userScrolling > 300) {
        this.showScroll = true;
      } else {
        this.showScroll = false;
      }
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss" scoped>
.scrollToTop {
  background-color: #ccc;
  border: 1px solid #333;
  border-radius: 1em;
  display: block;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 80px;
  height: 100px;

  .scrollImage {
    display: block;
    width: 100%;
    margin-top: 40px;
    text-align: center;
  }

  .scrollArrowTop {
    display: block;
    position: absolute;
    margin-left: 24px;
    text-align: center;
    font-size: 35px;
    color: dodgerblue;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover .scrollArrowTop {
    animation: arrowAnimation 700ms 2;
  }

  @keyframes arrowAnimation {
    from {
      color: red;
      margin-top: 8px;
    }

    to {
      color: dodgerblue;
      top: 0;
      margin-top: -8px;
    }
  }
}
</style>